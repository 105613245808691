<template>
  <v-container>
    <h5
      class="text-subtitle-1 secondary--text"
      v-if="!isMobile"
    >
      {{ $t('dispensing.product') }}
    </h5>
      <v-row no-gutters>
        <v-col cols="12"
          v-for="(product, index) in products"
          :key="product.productId"
          class="mt-3"
        >
          <v-row align="center" no-gutters>
            <v-col 
              class="mr-1" 
              cols="1"
              sm="1"
              md="1" 
              lg="auto"
            >
              <v-checkbox
                v-model="selectedProduct[index]"
                color="primary"
                :value="product"
                :disabled="!hasProvidersAvailable(index)"
                @change="updateValue(index, $event)"
                class="my-0"
              >
              </v-checkbox>
            </v-col>
            <v-col
              :class="[{'px-1' : isMobile}, {'enabled-by-existence' : !hasProvidersAvailable(index)}]" 
            >
              {{ product.productName }}
            </v-col>
            <v-col 
              cols="5" 
              sm="2"
              md="2"
              lg="auto"
              :order="isMobile ? '3' : '4'" 
            >
              <v-btn-toggle
                rounded
                class="prescription-table__quantity"
                :class="[{'prescription-table__quantity-disabled': !hasProvidersAvailable(index) || !selectedProduct[index]}, {'toggle-group': hasProvidersAvailable(index) && validatePurchaseMaxQuantityByProduct(index)}]"
              >
                <v-btn
                  @click="decreaseProduct(index)"
                  class="prescription-table__quantity-button toggle-group-btn"
                  v-ripple="{ class: `white--text` }"
                >
                  <v-icon
                    class="blue--text"
                    :class="{'blue-grey--text': !hasProvidersAvailable(index) || !selectedProduct[index]}"
                  >
                    mdi-minus
                  </v-icon>
                </v-btn>
                <v-btn
                  active-class="
                    prescription-table__quantity-active
                    prescription-table__quantity-disabled
                  "
                  class="prescription-table__quantity-button"
                >
                  {{ product.quantity }}
                </v-btn>
                <v-btn
                  @click="addedProduct(index)"
                  class="prescription-table__quantity-button"
                  v-ripple="{ class: `white--text` }"
                >
                  <v-icon
                    class="blue--text"
                    :class="{'blue-grey--text': !hasProvidersAvailable(index) || !selectedProduct[index]}"
                  >
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col 
              cols="12"
              sm="3" 
              md="2"
              lg="2"
              :order="isMobile ? '4' : '3'"
              :class="{'pb-2' : isMobile}"
            >
              <v-row 
                no-gutter 
                :align-self="isMobile ? 'center' : 'end'" 
                :justify="isMobile ? 'center' : 'end'"
                style="line-height: 0px"
              >
                <v-col 
                  cols="8" 
                  sm="12"
                  md="12"
                  lg="12"
                  class="pa-0 mr-8"
                  :align="isMobile ? 'start' : 'end'"
                >
                  <span 
                    class="caption osg-gray--text" 
                    :class="{'enabled-by-existence' : !hasProvidersAvailable(index)}"
                  >
                    {{ $tc("dispensing.prescribedQuantity", product.maxQuantity, { prescribedQuantity: product.maxQuantity }) }}
                  </span>
                </v-col>
                <v-col 
                  cols="8" 
                  sm="12"
                  md="12"
                  lg="12"
                  class="pa-0 mr-8"
                  :align="isMobile ? 'start' : 'end'"
                  v-if="validatePurchaseMaxQuantityByProduct(index)"
                >
                  <span 
                    class="caption osg-coral--text"
                    :class="{'enabled-by-existence' : !hasProvidersAvailable(index)}"
                  >
                    {{ $tc("dispensing.availableQuantity", provider.purchaseMaxQuantityByProduct, { availableQuantity: provider.purchaseMaxQuantityByProduct }) }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" order="5">
              <v-row no-gutters
                align-self="end"
                justify="end"
              >
                <v-col 
                  cols="11"
                  sm="11"
                  md="11"
                  lg="12"
                >
                  <v-alert
                    class="messag-alert ml-lg-8">
                    <template slot="prepend">
                      <v-icon v-if="product.providers.length > 0"
                        size="20"
                        class="mr-4" 
                        :color="getIconTypeColor(index)">mdi-check-circle</v-icon>
                      <v-icon v-else
                        size="20"
                        class="mr-4" 
                        :color="getIconTypeColor(index)">mdi-alert-circle</v-icon>
                    </template>
                    <template v-if="product.providers.length != 0">
                      {{ $tc("dispensing.productAvailableByProvider") }}
                      <template> 
                        {{ concatenateProvidersName(product.providers) }}.
                      </template>
                    </template>
                    <template v-else>{{ $tc("dispensing.productNotAvailable") }}</template> 
                  </v-alert>
                </v-col>
              </v-row>
            </v-col>
          </v-row> 
          <v-divider/>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  const prescriptionModule = createNamespacedHelpers('prescription');

  export default {
    name: 'Products',
    computed: {
      ...prescriptionModule.mapGetters(['products', 'provider', 'selectedProduct']),
      
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
    },
    methods: {
      ...prescriptionModule.mapActions(['updateProducts', 'addProduct', 'removeProduct']),
      updateValue (index, value) {
        const product = this.products[index];
        if (value) {
          this.addProduct({index, product})
        } else {
          this.removeProduct(index)
        }
      },
      addedProduct (index)  {
        const product = this.products[index]
        const newProducts = [...this.products]
        newProducts[index].quantity =  product.quantity < product.maxQuantity ? product.quantity + 1 : product.quantity
        this.updateProducts(newProducts);
      },
      decreaseProduct (index)  {
        const product = this.products[index]
        const newProducts = [...this.products]
        newProducts[index].quantity =  (product.quantity > 1) ? product.quantity - 1 : 1;
        this.updateProducts(newProducts);
      },
      validatePurchaseMaxQuantityByProduct (index) {
        if(this.provider.purchaseMaxQuantityByProduct) {
          const product = this.products[index]          
          return product.quantity > this.provider.purchaseMaxQuantityByProduct;
        }
        return false
      },
      concatenateProvidersName(providers){
        let concatenateProvidersName = [];
        providers.forEach(provider => {
          concatenateProvidersName.push(provider.providerName);
        });
        return concatenateProvidersName.join(" y ");
      },
      hasProvidersAvailable (index) {
        const providers = this.products[index].providers;

        const withOutProviderIdAndWithProviders = this.provider.providerId === undefined && providers.length > 0;
        const hasProvidersByProviderId = providers.filter(provider => provider.providerId === this.provider.providerId).length > 0;
  
        return withOutProviderIdAndWithProviders || hasProvidersByProviderId;
      },
      getIconTypeColor(index) {

        const providers = this.products[index].providers;

        const withOutProviderIdAndWithProviders = this.provider.providerId === undefined && providers.length > 0;
        const hasProvidersByProviderId = providers.filter(p => p.providerId === this.provider.providerId).length > 0

        if (withOutProviderIdAndWithProviders || hasProvidersByProviderId) {
          return 'success';
        }else if (providers.length === 0) {
          return 'error'
        } 
        return null;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .prescription-table {
    min-height: 250px;
    height: auto;
    
    &__quantity {
      display: flex;
      justify-content: flex-end;

      &-button {
        background-color: white !important;
      }

      &-disabled {
        pointer-events: none;
        color: gray;
      }

      &-active {
        background-color: white !important;
      }
    }

    &__line {
      background-color: #e0e0e0;
    }
  }
  .theme--light.toggle-group:not(.v-btn-toggle--group) .v-btn.v-btn {
    border-color: #ff726d !important;
  }

  .v-alert {
    padding: 0 !important;
    font-size: 14px !important;
  }

  .enabled-by-existence {
    color: #dfdfdf !important;
  }

  .message-alert > .v-alert__border {
    display: none !important;
    border-style: none !important;
    
  }

  ::v-deep .v-messages{
    display: none;
  }

  @media (max-width: 600px) {
    .prescription-table {
      height: auto;
    }
  }

</style>
