<template>
  <validation-observer
    ref="observer"
    v-model="valid"
    lazy-validation
  >
    <v-form @submit.prevent="handleSubmit()">
      <v-container>
        <PrescriptionErrorAlert
          v-show="error"
          class="mt-8"
          :message="$t(error)"
        />
        <v-row
          align="center"
          justify="center"
        >
          <SelectProvider
            :options="providers"
            :label="$t('provider')"
            required
            v-model="providerData"
          />
          <v-col
            class="d-flex justify-center justify-md-end justify-sm-end justify-lg-end pr-0"
            :class="{'contact-form__left': isMobile}"
          >
            <span class="text-body-2 osg-gray--text">
              {{ $t('dispensing.recipeCode') }}
            </span>
            <span
              class="text-body-2
                    mx-4
                    font-weight-black
                    text-uppercase
                    error--text"
            >
              {{ prescription.code }}
            </span>
          </v-col>
          <v-col 
            cols="12" 
            class="px-7" 
            v-if="PurchaseMaxQuantityByProductError.length > 0">
            <p 
              class="text-body-2 osg-gray--text"
              :class="{'text-right': !isMobile}"
            >
              {{ $t('error.purchaseMaxQuantityByProduct') }}
            </p>
          </v-col>
        </v-row>
      </v-container>
      <Products
        v-model="selectedProduct"
      />
      <v-container>
        <template v-if="provider.hasForm">
          <DeliveryForm
            v-for="form in forms"
            :key="form.fallbackName"
            :form='form'
          />
        </template>
        <v-row
          class="contact-form"
          no-gutters
        >
          <v-col
            cols="12"
          >
            <PrescriptionErrorAlert
              v-show="errorGeneral"
              class="mt-8"
            />
          </v-col>
          <v-col
            class="text-subtitle-2 contact-form__button pt-8"
            cols="12"
          >
            <v-btn
              rounded
              x-large
              height="40"
              width="200"
              class="mr-0 contact-form__button"
              color="info"
              type="submit"
              :disabled="!orderCanBeCreated"
              v-if="!isMobile"
            >
              <span>
                {{ $t('button.createOrder') }}
              </span>
            </v-btn>
            <v-btn
              rounded
              class="mr-0 contact-form__button"
              color="info"
              type="submit"
              :disabled="!orderCanBeCreated"
              v-if="isMobile"
            >
              <span>
                {{ $t('button.createOrder') }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </validation-observer>
</template>

<script>  
  import { ValidationObserver } from 'vee-validate/dist/vee-validate.full';

  import Products from "@/components/dispensing/Products.vue";
  import SelectProvider from '@/components/forms/SelectProvider.vue';
  import DeliveryForm from '@/components/forms/DeliveryForm.vue';
  import PrescriptionErrorAlert from '@/components/general/PrescriptionErrorAlert.vue';

  import { createNamespacedHelpers } from 'vuex'
  const prescriptionModule = createNamespacedHelpers('prescription');

  export default {
    name: 'DispensingForm',
    components: {
      ValidationObserver,
      Products,
      SelectProvider,
      PrescriptionErrorAlert,
      DeliveryForm
    },
    data: () => ({
      valid: true,
      formData: {
        prescriptionExposure:null,
        providerId: null,
        countryCode: null,
        paymentType: null,
        paymentName: null,
        givenNames: null,
        surnames: null,
        phoneNumber: null,
        emailAddress: null,
        address: null,
        taxIdentificationNumber: null,
        products: [],
      },
      address:"",
      showErrorGeneral: false,
    }),
    computed: {
      ...prescriptionModule.mapGetters(['prescription', 'prescriptionExposureId', 'providers', 'provider', 'deliveryCities', 'paymentMethods','forms','error','formDataCollected', 'selectedProduct']),
      isMobile () {
        return this.$vuetify.breakpoint.xsOnly
      },
      orderCanBeCreated () {
        const { providerId } = this.formData;

        if(this.provider.purchaseMaxQuantityByProduct) {
          return (providerId && Object.entries(this.selectedProduct).length && Object.entries(this.PurchaseMaxQuantityByProductError).length == 0);
        }

        return (providerId && Object.entries(this.selectedProduct).length);
      },
      errorGeneral () {
        return this.showErrorGeneral;
      },
      providerData:{
        get(){
          return this.value;
        },
        set(value) {
          this.formData.providerId = value.providerId;
          this.formData.notificationType = value.notificationType;
          this.validateProviderProduct();
        }
      },
      PurchaseMaxQuantityByProductError(){
        if(this.provider.purchaseMaxQuantityByProduct) {
          const products = this.selectedProduct;
          return  products.filter(product => product.quantity > this.provider.purchaseMaxQuantityByProduct)
        }
        return [];
      }
    },
    methods: {
      ...prescriptionModule.mapActions(['createPrescriptionDelivery','setError', 'removeProduct']),
      async handleSubmit () {
        const isValid = await this.$refs.observer.validate();
        this.valid = isValid;
        this.showErrorGeneral = !isValid;

        if (this.valid) {
          if(this.provider.hasForm) {
            const paymentMethod = this.paymentMethods.filter(payment => payment.type === this.formDataCollected.paymentType);
            this.formData.paymentName = (paymentMethod.length) ? paymentMethod[0]['name'] : null;
            this.formData.paymentType = this.formDataCollected.paymentType;
            this.formData.phoneNumber = this.formDataCollected.phoneNumber;
            this.formData.taxIdentificationNumber = this.formDataCollected.taxIdentificationNumber;

            if(this.formDataCollected.givenNames != null){
              this.formData.givenNames = this.convertToTitleSentence(this.formDataCollected.givenNames);
            }
            if(this.formDataCollected.surnames != null){
              this.formData.surnames = this.convertToTitleSentence(this.formDataCollected.surnames);
            }
            if(this.formDataCollected.emailAddress != null){
              this.formData.emailAddress = this.formDataCollected.emailAddress.toLowerCase();
            }
            if(this.formDataCollected.address != null && this.formDataCollected.city != null){
              this.formData.address = this.formDataCollected.address + ". " + this.formDataCollected.city + ", " + this.prescription.countryName;
            }
          }
          
          this.formData.prescription_exposure = this.prescriptionExposureId;
          this.formData.countryCode = this.prescription.doctorCountryCode;

          const filterProducts =  this.selectedProduct
          .filter(product => product.providers
                            .map(provider => provider.providerId)
                            .includes(this.formData.providerId));
          this.formData.products = filterProducts;
          await this.createPrescriptionDelivery(this.formData);
        }
      },
      convertToTitleSentence (value) {
        return value.toLowerCase().split(' ').map((letter) => {
          return letter.replace(letter[0], letter[0].toUpperCase());
        }).join(' ');
      },
      validateProviderProduct() {
        for(var i = 0; i < this.selectedProduct.length; i++){
          let hasProvider = true;
          if(this.selectedProduct[i] != undefined){
            for(var j = 0; j < this.selectedProduct[i].providers.length; j++){
              if(this.selectedProduct[i].providers[j].providerId == this.formData.providerId){
                hasProvider = true;
                break      
              }else{
                hasProvider = false;
              }
            }
            if(!hasProvider){
              this.removeProduct(i);
            }

          }
        }
      }
    },
  }
</script>

<style lang="scss">
  .contact-form {
    &__error {
      font-size: 12px;
      line-height: 12px;
      word-break: break-word;
    }

    &__button {
      display: flex;
      justify-content: flex-end;
      text-align: center;

      span {
        margin: 0 auto;
      }
    }

    &__left {
      display: flex;
      justify-content: flex-start;
    }
  }

  @media (max-width: 600px) {
    .contact-form {
      display: flex;
      flex-direction: column;

      &__button {
        width: 100%;
      }
    }
  }
</style>
