<template>
<v-container>
    <h5 class="text-subtitle-1 secondary--text">
      {{ form.fallbackName }}
    </h5>
    <v-row>
      <template v-for="field in filteredAndsortedFields">
        <DeliverySelectField
          v-if="field.type === 'SELECT'"
          :key="field.fieldSlug"
          :label="field.fallbackLabel" 
          :items="field.items"
          @input="changeInput($event, field)" 
        ></DeliverySelectField>
        <DeliveryField
          v-else
          :key="field.fieldSlug"
          :field="field"  
          :ref="field.fieldSlug"
          :rules="fieldRules(field)"
          :defaultValue="field"
          @input="changeInput($event, field)" 
        ></DeliveryField>
      </template>
    </v-row>
</v-container>
</template>
<script>
import DeliveryField from './DeliveryField'
import DeliverySelectField from './DeliverySelectField'
import { createNamespacedHelpers } from 'vuex'
const prescriptionModule = createNamespacedHelpers('prescription');

export default {
  name: "DeliveryForm",
  components:{
    DeliveryField,
    DeliverySelectField
  },
  props: {
    form: Object
  },
  data: () => ({
    rules: {}
  }),
  computed: {
    ...prescriptionModule.mapGetters(['prescription','deliveryCities', 'paymentMethods']),

    filteredAndsortedFields: function () {
      let fields = this.form.fields;
      fields.sort((a, b) => (a.showOrder > b.showOrder) ? 1 : -1);

      fields.forEach(field => {
        if(field.fieldSlug === 'payment-type'){
          field.items = this.paymentMethods;
        }else if(field.fieldSlug === 'city'){
          field.items = this.deliveryCities;
        }
        if(field.fieldSlug === 'country') {
          field.defaultValue = this.prescription.countryName;
          field.enabled = false;
        }
      });

      return fields;
    }
  },
  methods:{
    ...prescriptionModule.mapActions(['setFormDataCollected']),
    changeInput(event, field) {
      this.setFormDataCollected({ slug: field.fieldSlug, event: event });
    },
    fieldRules(field){
      return {
        required: field.required, 
        max: field.characterMaxLength,
        email: field.type === 'EMAIL',
        numeric: field.type === 'NUMBER'
      }
    } 
  }
    
}
</script>