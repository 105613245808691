<template>
  <v-alert
    color="error"
    border="left"
    class="prescription-error text-xs-subtitle-1"
    icon="mdi-alert"
    transition="scroll-x-reverse-transition"
    :prominent="isMobile"
    elevation="6"
    colored-border
  >
    <span v-if="!message"> {{ $t('error.general') }} </span>
    <span v-if="message"> {{ message }} </span>
  </v-alert>
</template>

<script>
  export default {
    name: 'PrescriptionErrorAlert',
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
    },
    props: {
      message: {
        type: String,
        default: '',
      },
    },
  };
</script>

<style lang="scss" scoped>
  .prescription-error {
    background-color: #f2f2f2;
  }
</style>
