<template>
  <v-col
    cols="12"
    sm="4"
  >
    <validation-provider
      v-slot="{ errors }"
      :rules="rules"
      name="select"
    >
      <v-select
        v-model="selectProvider"
        :items="options"
        :error-messages="errors"
        item-text="providerName"
        item-value="providerId"
        return-object
      >
        <template v-slot:label>
          {{ label }}
          <span
            v-if="required"
            class="red--text"
          >
            *
          </span>
        </template>
      </v-select>
    </validation-provider>
  </v-col>  
</template>

<script>
  import { ValidationProvider } from 'vee-validate';

  import { createNamespacedHelpers } from 'vuex'
  const prescriptionModule = createNamespacedHelpers('prescription');

  export default {
    name: "SelectProvider",
    components: {
      ValidationProvider,
    },
    data: () => ({
      value: "",
    }),
    props: {
      rules: {
        type: String,
        default: "",
      },
      options: {
        type: Array,
        default: () => [],
      },
      label: {
        type: String,
      },
      required: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      selectProvider: {
        get () {
          return this.value.providerId;
        },
        set (value) {
          this.$emit('input', value)
          this.setCurrentProvider(value);
        },
      },
    },
    methods: {
      ...prescriptionModule.mapActions(['setCurrentProvider']),
    }
  }
</script>
