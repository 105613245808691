<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PrescriptionErrorAlert
          v-if="!hasProviders"
          :message="$t('hasProviders')"
        />
      </v-col>
      <v-col cols="12">
        <PrescriptionErrorAlert
          v-if="!hasProducts"
          :message="$t('hasProducts')"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>  
  import PrescriptionErrorAlert from '@/components/general/PrescriptionErrorAlert.vue';

  import { createNamespacedHelpers } from 'vuex'
  const prescriptionModule = createNamespacedHelpers('prescription');

  export default {
    name: 'ExceptionAlerts',
    data: () => ({
      isSecondaryText: true,
      hasProviders: false,
      hasProducts: false,
    }),
    mounted () {
      if (this.providers.length) {
        this.hasProviders = true;
      }
      if (this.products.length) {
        this.hasProducts = true;
      }
    },
    components: {
      PrescriptionErrorAlert,
    },
    computed: {
      ...prescriptionModule.mapGetters(['providers', 'products']),
    },
  }
</script>

<style lang="scss" scoped>
  .header {
    background-color: #f9f9f9 !important;
  }
</style>
