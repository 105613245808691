<template>
  <v-col cols="12" sm="4">
    <validation-provider
      v-slot="{ errors }"
      :rules="rules"
    >
      <v-text-field 
        v-model="inputValue"
        :type="field.type.toLowerCase()"
        :error-messages="errors"
        :label="field.fallbackLabel"
        :disabled="!field.enabled"
        @input="$emit('input', $event)"
      >
        <template v-slot:label>
          {{ field.fallbackLabel }}
          <span
            v-if="field.required"
            class="red--text"
          >
            *
          </span>
        </template>
      </v-text-field>
    </validation-provider>
  </v-col>  
</template>
<script>
import { ValidationProvider } from 'vee-validate';
export default {
    name:"DeliveryField",
    components: {
      ValidationProvider
    },
    data: () => ({
      value: '',
    }),
    props: {
      options:[],
      rules: Object,
      field:{
        type: Object,
        default: () => ({
          fieldSlug: null,
          typeSlug: null,
          fallbackLabel: null,
          showOrder: null,
          required: null,
          description: null,
          defaultValue: null,
          enabled: null
        })
      },
  },
  created (){
    this.value = this.field.defaultValue;
  },
  computed: {
    inputValue: {
      get () {
        return this.value;
      },
      set (val) {
        this.value = val;
      },
    }
  }
}
</script>